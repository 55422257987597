<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Jobs"
              url="/pages/masterlists/jobs/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-update-cancel="(v) => loadJobs()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-status-change="(v) => (status = v)"
              @on-search="(v) => (keyword = v)"
            >
              <template #columns>
                <DxColumn data-field="code" caption="Code" />
                <DxColumn data-field="name" caption="Name" />
                <DxColumn data-field="job_type" caption="Type" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import jobApi from '@api/jobs';
  import { queryParameters } from '@/schema';

  export default {
    name: 'JobsPage',
    components: {},
    data: () => ({
      dataSource: [],
      keyword: '',
      totalCount: 0,
      page: 1,
      size: 10,
      status: 'O',
    }),
    watch: {
      page(v) {
        this.loadJobs();
      },
      size(v) {
        if (v !== queryParameters.page.size) {
          this.loadJobs();
        }
      },
      keyword(v) {
        this.loadJobs();
      },
      status(v) {
        this.loadJobs();
      },
    },
    mounted() {
      this.loadJobs();
    },
    methods: {
      loadJobs() {
        const params = {
          sort: '-updated_at',
          page: { size: this.size, after: this.page },
          status: this.status,
        };

        jobApi
          .list(params)
          .then(({ data, meta }) => {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
      onViewDetail(event) {
        const data = event.data;

        if (data) {
          const url = `/pages/masterlists/jobs/view?id=${data.id}`;
          this.$router.push(url);
        }
      },
      onDelete(event) {
        jobApi
          .delete(event)
          .then(({ data }) => {
            this.loadJobs();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      },
    },
  };
</script>

<style></style>
